// Styles
.container-fluid {
  width: calc(100% - 24px) !important;
}
.top-nav{
  width: 200px;
  float: left;
  margin-top: 25px;
  list-style: none;
  padding: 0;
  li{
    float: left;
    margin-right: 30px;
  }
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}

h1 {
  font-size: 18px !important;
}

h2 {
  font-size: 16px !important;
}

.btn {
  box-shadow: none !important;
  line-height: 1 !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  height: 36px !important;
  border: none !important;

  &.btn-primary, &.btn-secondary, &.btn-info, &.btn-danger {
    border-radius: 3px;
    padding: 0 10px;
  }
}

.tableColumnMinSize {
  width: 1% !important;
  white-space: nowrap !important;
}

.form-check {
  display: flex !important;
  align-items: center;
  margin-bottom: 1rem;

  input {
    margin-top: 0;
  }

  label {
    margin-bottom: 0;
    line-height: 1;
  }
}

.thNoBorder {
  th {
    border: none !important;
  }
}

#voting_options {
  margin-top: 2rem;

  p {
    font-weight: 700;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    div, span {
      flex: 1 1 100%;
      margin: 0;
    }

    button {
      flex: 0 0;

      &.textarea-button {
        align-self: flex-end;
      }
    }
  }
}

// WEG-Übersicht
.container-fluid {
  max-width: 1500px;

  .headRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0 30px 0;

    div {
      &:first-of-type {
        display: flex;
        align-items: center;
        font-size: 18px;
        flex: 1 1 50%;

        i {
          margin: 0 15px 5px 0;
          font-size: 22px;
          color: #0064D1;
          cursor: pointer;
        }
      }

      &.multipleButtons {
        gap: 15px;
        display: flex;
      }

      &.topButton {
        button {
          border-radius: 50px;
          padding: 0 20px;
        }
      }
    }

    h1, h2 {
      margin-bottom: 0;
    }

    .attendingParticipants {
      font-size: 0.9rem;
      margin-top: 3px;
      margin-left: 20px;
      color: #0064D1;
      font-weight: 600;
    }

    @media (max-width: 666px) {
      display: block;

      div {
        display: block !important;

        a {
          display: block;
          margin-bottom: 10px;
        }

        .attendingParticipants {
          margin-left: 0;
        }
      }

      .btn {
        border-radius: 50px;
        padding: 0 20px;
      }

      .topButton {
        button {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }

  .overviewTable {
    display: block;
    box-shadow: 0 0 35px rgba(154, 161, 171, 0.15);
    border-radius: 3px;
    background: #fff;
    padding: 20px;

    h1, h2 {
      margin-bottom: 0;
    }

    > .col {
      &:first-of-type {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 30px;
      }

      &:nth-of-type(2) {
        padding: 0 !important;
        overflow: auto;

        .table {
          th, td {
            vertical-align: middle !important;
            padding-top: 20px;
            padding-bottom: 18px;
          }
        }
      }
    }

    .col-sm-12 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;

      i, .pageNumber {
        display: inline-block;
        width: 30px;
        text-align: center;
        padding: 4.5px 0;
        border-radius: 15px;
        margin-right: 5px;
        cursor: pointer;

        &.active {
          color: #fff;
          background: #0064D1;
        }
      }

      i:last-of-type {
        margin-right: 0;
      }
    }
  }

  .weg-list, .meeting-list {
    td {
      &:first-of-type {
        min-width: 250px;
      }
    }

    .flex-td {
      min-width: 200px;

      i {
        vertical-align: middle;
      }

      &.grayed-out {
        color: #B5B5BD;
      }
    }

    a {
      font-weight: 600;
    }

    i {
      font-size: 18px !important;
      margin-right: 10px;
    }

    .meetingClosed {
      color: #fa5c7c;
    }
  }
}

// Modal
.modal {
  .modal-content {
    .modal-header {
      border-bottom: none !important;
      padding: 20px 20px 15px 20px;

      h5 {
        font-size: 18px;
      }
    }

    .modal-body {
      padding: 15px 20px 20px 20px;
    }

    .processingButtons {
      text-align: right !important;

      .btn {
        margin: 15px 15px 0 0 !important;

        &:last-of-type {
          margin-right: 0 !important;
        }
      }

      &.qrButton {
        text-align: center !important;
      }
    }
  }

  .result-modal.modal-xl {
    .modal-content {
      //max-height: 90vh;

      .modal-body {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: 100%;
        grid-gap: 20px;
        min-height: 0;

        .resultHistory {
          margin-top: 0;
          overflow-y: auto;
        }
      }
    }

    &.no-not-voted-log {
      .modal-body {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (max-width: 1366px) {
      .modal-content {
        max-height: unset;

        .modal-body {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: unset;

          > div {
            &.result-diagram {
              grid-column: 1 / 3;
            }
          }
        }
      }

      &.no-not-voted-log {
        .modal-content {

          .modal-body {
            > div {
              grid-column: 1 / 3;
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .modal-content {
        .modal-body {
          grid-template-columns: 1fr;

          > div {
            &.result-diagram {
              grid-column: 1 / 2;
            }
          }
        }
      }
    }
  }
}

// Tooltips
.tooltip-icon {
  margin-left: 0.3em;
  color: #0064D1;
  cursor: pointer;
}

// Card
.card {
  border: none !important;
  box-shadow: 0 0 35px rgba(154, 161, 171, 0.15);
  border-radius: 3px !important;
}

// Meeting Overview
.meetingOverviewTile {
  margin-bottom: 20px;

  .card {
    width: 100%;

    .card-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .card-text {
      > div {
        display: flex;
        margin-top: 80px;

        @media (max-width: 1024px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          gap: 15px;
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
          grid-template-rows: unset;
        }

        span {
          padding-right: 25px;
          border-right: 1px solid #eee;
          padding-left: 25px;

          @media (max-width: 1024px) {
            padding: 0;
            border-right: none;
          }

          &:last-of-type {
            padding-right: 0;
            border-right: none;
          }

          &:first-of-type {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.agendaItem {
  > div > div {
    margin-bottom: 10px !important;

    &.closed {
      opacity: 0.6;
    }
  }

  .card-header {
    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 500px) {
        display: block;

        button {
          margin-left: 25px;
        }

        .btn-group {
          position: absolute;
          right: 0;
          top: 20px;
        }
      }

      .nav-link {
        padding: 0;
        line-height: 1.4;
        display: flex !important;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        color: #6C757D;
        max-width: 80%;

        @media (min-width: 769px) {
          max-width: 60%;
        }

        .agendaItemTitleLink {
          span {
            &.abstimmungenCount {
              color: #0064D1;
              white-space: nowrap;
              display: inline-block;
              margin-top: 10px;
            }

            &.beschlussfaehigState {
              margin-left: 20px;
              color: #fa5c7c;

              i {
                margin-right: 6px;
              }
            }

            @media (max-width: 768px) {
              display: block;

              &.abstimmungenCount,
              &.beschlussfaehigState {
                margin-left: 0;
                margin-top: 5px;
              }
            }
          }
        }

        > span {
          line-height: 1;
          margin-right: 5px;

          i {
            font-size: 20px;
          }
        }
      }

      button {
        i {
          font-size: 20px;
          color: #B5B5BD !important;
        }
      }
    }

    .dropdown-menu {
      left: unset !important;
      right: 0;
      transform: translate3d(0, 30px, 0) !important;
    }
  }
}

.addTopModal {
  .threeColumnRow {
    > div {
      &:last-of-type {
        display: flex;
        align-items: flex-end;
        column-gap: 10px;

        .form-group {
          &:first-of-type {
            flex: 1 0 70%;
          }
          &:nth-of-type(2) {
            flex: 1 0 30%;
          }
        }

        @media (max-width: 425px) {
          flex-wrap: wrap;

          .form-group {
            flex: 1 0 100% !important;
          }
        }
      }
    }
  }
}

// Agenda-Item
.topDetails {
  display: flex;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 30px;
  }

  > div {
    &:first-of-type {
      flex: 2;
      display: grid;
      //grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      padding: 40px;
      background: rgba(54, 169, 225, 0.15);
      border-radius: 3px;
      margin: 0 40px 30px 0;
      gap: 30px;

      p {
        margin-bottom: 0;

        &:last-of-type {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      @media (max-width: 768px) {
        margin: 0 0 30px;
      }

      @media (max-width: 425px) {
        grid-template-rows: 1fr;
        grid-template-columns: unset;

        p {
          &:last-of-type {
            grid-column-end: 2;
          }
        }
      }
    }

    &:last-of-type {
      flex: 1;
      max-width: 35%;

      @media (max-width: 768px) {
        max-width: unset;
      }

      > p {
        font-weight: bolder;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 8px 12px 7px;
          background: rgba(140, 140, 140, .15);
          border-radius: 3px;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          span {
            flex: 1 1 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          i {
            font-size: 1.3rem;

            &:first-of-type {
              color: #36A9E1;
            }

            &.mdi-delete, &.mdi-download {
              margin-left: auto;
              transition: all .1s ease-in-out;
              cursor: pointer;
            }

            &.mdi-delete:hover {
              color: #fa5c7c;
            }

            &.mdi-download:hover {
              color: #36A9E1;
            }
          }
        }
      }
    }
  }


}
.custom-accordion{
  .card{
    box-shadow: 0 0 0;
    .custom-accordion-title {
      color: #313a46;
      background: #f1f3fa;
      position: relative;
    }
  }
}
.top-head{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .topButtons{
    margin-top: 15px;
    max-width: 100%;

    button {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}


#votingSection {
  .votingRow {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr 0.7fr min-content min-content;
    grid-gap: 15px;

    h5 {
      flex: 1 1 30%;
    }

    .additionalButtons {
      display: flex;
      flex-grow: 1;
      flex-basis: 1%;
      justify-content: space-between;
      grid-gap: 15px;
      align-items: center;

      button {
        flex: 1 1 100%;
      }
    }

    @media (max-width: 900px) {
      display: grid;
      border: 1px solid #eee;
      padding: 30px;
      border-radius: 3px;
      grid-template-columns: 1fr !important;
      grid-template-rows: unset;
      text-align: center;

      .additionalButtons {
        flex-direction: column;

        button {
          flex: 0 0 36px;
          width: 100%;
        }
      }
    }

    * {
      display: inline;
      margin: 0;
    }
    .voteingType{
      padding-top: 12.5px;
    }

    .votingPlayButtons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;

      @media (max-width: 900px) {
        justify-content: center;
      }

      span {
        font-size: 30px;
        line-height: 0;

        i {
          cursor: pointer;
          display: inline-block;

          &.paused {
            animation: blinking 1s infinite;

            @keyframes blinking {
              0% {color: #fa5c7c;}
              49% {color: #fa5c7c;}
              50% {color: #6c757d;}
              100% {color: #6c757d;}
            }
          }

          &:before {
            line-height: 0.6;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        &.votingStatus {
          font-size: 0.9rem;
          margin-left: 10px;
          margin-bottom: 3px;
        }
      }
    }

    .votingDone {
      font-weight: 600;

      i:before {
        display: inline-block;
        margin: 0 3px 3px 0;
        font-size: 1rem;
      }
    }

    > .col {
      padding: 0;

      button {
        height: 36px !important;

        @media (max-width: 900px) {
          width: 100%;
        }
      }

      @media (max-width: 900px) {
        &:last-of-type {
          margin-top: -10px;
        }
      }
    }

    &.gridTemplateWithDeputy {
      grid-template-columns: 2fr 1fr 0.7fr min-content min-content min-content;

      @media (max-width: 900px) {
        border: 1px solid #eee;
        padding: 30px;
        border-radius: 3px;
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        text-align: center;
        gap: 20px;

        > .col {
          &:nth-last-of-type(2), &:last-of-type {
            margin-top: -10px;
          }
        }
      }
    }
  }

  hr {
    @media (max-width: 900px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
}

.votingTemplateRow {
  #votingSection {
    width: 100%;

    .card-body {
      .votingRow {
        grid-template-columns: auto min-content min-content min-content;
        align-items: center;

        span {
          line-height: 1.4;
        }
      }
    }
  }
}

#deputyVoteModal {
  table {
    td, th {
      vertical-align: middle;
    }

    th {
      i {
        margin-left: 3px;
      }

      &.auswahl-th {
        white-space: nowrap;

        input {
          margin-right: .5em;
        }
      }

      &:nth-of-type(2), &:nth-of-type(3) {
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    tr {
      td {
        .form-check {
          display: block !important;
          align-items: unset;
          margin: 0;
          padding: 0;
          line-height: 0;
          text-align: center;

          input[type=radio] {
            position: relative;
            margin: 0;
            padding: 0 !important;
          }
        }
      }
    }

    .groupVotingRow {
      background: #eee;
      margin-top: 10px;
      border-radius: 5px;

      td {
        border-top: none;
      }

      span {
        font-weight: 700;
      }

      ul {
        padding-left: 1.5em;
        margin-bottom: 0;
      }
    }
  }

  .votingModalvotingTitle {
    font-size: 17px !important;
    margin-bottom: 5px;
  }
}

// Footer
footer {
  display: none !important;
}

// File-Uploader
.dz-message {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 15px;
  text-align: center;
  outline: none;

  h5 {
    margin: 10px 0 0;
  }
}

.dz-processing {
  .col-auto {
    width: 100%;
  }
  .avatar-sm {
    width: 100%;
    display: block;
    margin-bottom: 5px;

    span {
      background: #ddd;
    }
  }
}

.dropzone-previews {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.375rem;

  > div {
    flex: 0 calc(50% - 0.1875rem);
  }
}

// Results
.resultTable {
  display: table !important;
}

// Teilnehmerliste
#memberlist {
  &.xxl {
    margin: 1.75rem auto !important;
    max-width: calc(100% - 3.5rem) !important;
    width: 1400px !important;
  }

  .modal-body {
    > .row {
      &:first-of-type {
        > .col {
          display: flex;
          grid-column-gap: 15px;
          grid-row-gap: 10px;
          flex-wrap: wrap;
          justify-content: flex-end;

          .form-group {
            margin-right: auto;
            flex: 0 0 250px;
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            justify-content: stretch;

            .form-group {
              flex: 0 1 100%;
            }

            button {
              flex: 1 1 40%;
            }
          }

          @media (max-width: 511px) {
            justify-content: stretch;

            button {
              flex: 1 1 100%;
            }
          }
        }
      }

      &:nth-of-type(2) {
        .vollmacht {
          background: #ddd;
          border-radius: 5px;
          padding: 15px 20px;
        }
      }

      &:last-of-type {
        table {
          th {
            /*&:last-of-type {
              width: 1%;
            }*/

            &.thQrCode, &.thPresent {
              width: 1%;
              white-space: nowrap;
            }

            i {
              margin-left: 3px;
            }

            &:first-of-type, &:nth-of-type(2) {
              cursor: pointer;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
          }

          td {
            vertical-align: middle;
            line-height: 1;

            p:last-of-type, .form-group {
              margin-bottom: 0;

              label {
                display: none;
              }
            }

            &.tdQrCode {
              text-transform: uppercase;

              span {
                display: block;
                margin-top: 0.5em;
              }

              .mdi-qrcode-scan {
                font-size: 24px;
                line-height: 1;
                color: #0064D1;
                cursor: pointer;
                transition: all .2s;

                &:hover {
                  opacity: 0.6;
                }
              }
            }

            &.tdPresent {
              text-align: center;
            }

            &.editButtons {
              > button {
                padding: 0 10px;
              }
            }
          }
        }
      }

      &#importParticipant {
        margin-top: 0;

        div {
          text-align: center;
        }

        .dropzone {
          width: 100%;
        }

        .dropzone-previews {
          > .card {
            flex-basis: 100%;

            > div {
              > div:nth-child(2) {
                min-width: 0;

                span:first-child {
                  display: block;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }
          }
        }

        #file-previews {
          .file-tile {
            display: grid;
            line-height: 1;
            grid-template-rows: 1fr;
            grid-template-columns: 120px auto min-content;
            column-gap: 15px;
            align-items: center;

            .avatar-sm {
              margin: 0;

              span {
                display: block;
                padding: 5px;
              }
            }

            div {
              text-align: left;
            }

            .mdi-delete {
              transition: all .1s ease-in-out;
              cursor: pointer;
              font-size: 1.3rem;

              &:hover {
                color: #fa5c7c;
              }
            }
          }
        }
      }
    }
  }

  .onlineStatus {
    display: none;
    background: #39e08b;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 8px;

    &.online {
      display: inline-block;
    }
  }
}

// Login-Card
#loginCard {
  position: absolute;
  width: 90%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .card-header {
    border-bottom: 0;

    h3 {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

// Spinner
.spinner {
  margin: 50px auto;
  width: 100px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: #0064D1;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

// Navbar-User
#topbar-userdrop {
  padding: 20px !important;

  span {
    display: block !important;

    &:first-of-type {
      margin-bottom: 5px;
      font-weight: 700;
    }
  }
}

.navbar-custom {
  > .container-fluid {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .top-nav {
      margin: 0;
    }

    .topnav-logo {
      span {
        img {
          margin-bottom: 5px;
        }
      }
    }
  }

  .topbar-right-menu {
    margin-left: auto;

    .nav-link {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      padding-left: 20px !important;
      padding-right: 20px !important;

      @media (max-width: 767px) {
        max-width: 210px;

        > span {
          min-width: 0;

          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;

  .notify-item {
    padding: 12px 20px !important;
  }
}

// Error-Message
.errorMessage {
  border: 1px solid #f44336;
  background: rgba(244, 67, 54, 0.1);
  border-radius: 0.25rem;
  padding: 15px;
  margin-top: 0.375rem;
  color: #f44336;
}

// Dropdowns
.dropdown-menu {
  border-radius: 3px !important;

  .dropdown-item {
    padding: 0.75em 1em !important;
    line-height: 1;
  }
}

// Tables
.table thead th {
  border-top: none !important;
}

// Alerts
.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 -40px !important;
  width: 100%;
  padding: 1.1em 1em 1em !important;

  &.alert-success {
    color: #216d3e;
    background-color: #cbf6db;
    border-color: #b9e4c9;
  }

  p {
    line-height: 1.4;
    margin: 0;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    line-height: 0;
    font-size: 22px;
    opacity: 0.6;
  }
}

.modal {
  .alert {
    margin: 0 0 30px !important;
  }
}

// Form Validation
input, textarea, select {
  padding: 0.45rem 0.4rem 0.45rem 0.6rem !important;
}
textarea{
  min-height: 120px;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    background: none !important;
}

// Ergebnisse
.resultBox {
  padding: 15px 20px;
  background: #36A9E1;
  border-radius: 3px;
  color: #fff;
  margin-bottom: 30px;

  p {
    &:first-of-type {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0.4rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.chartResultBox {
  text-align: center;
  margin-bottom: 15px;
  padding: 15px 20px;
  background: #36A9E1;
  border-radius: 3px;
  color: #fff;
}

.result-modal {
  .new-tab-link {
    font-size: 1rem;
    margin-left: 5px;
  }
}

.resultHistory {
  margin-top: 30px;

  h5 {
    font-size: 16px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    grid-gap: 10px;
    flex-wrap: wrap;

    p {
      display: inline-block;
      cursor: default;
      padding: 6px 10px;
      background: #eee;
      border-radius: 3px;
      margin: 0;
      position: relative;
    }
  }

  > p {
    font-size: 14px;
    margin: 15px 0 0;
    color: #ccc;
  }
}

.single-result {
  max-width: 600px;

  > .row {
    &.headRow {
      padding: 70px 0 30px 0;

      h1 {
        margin: 0;
      }
    }

    .card {
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: 100px;
    }
  }

  &.single-result-big {
    max-width: 100%;
    width: 1400px !important;

    > .row {
      margin-bottom: 0;

      > .card {
        @media (min-width: 736px) {
          max-height: 70vh;
        }

        > .card-body {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-template-rows: 100%;
          grid-gap: 20px;
          min-height: 0;

          .resultHistory {
            margin-top: 0;
            overflow-y: auto;
          }
        }
      }
    }

    @media (max-width: 1366px) {
      .card {
        max-height: unset !important;

        .card-body {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: unset !important;

          > div {
            &.result-diagram {
              grid-column: 1 / 3;
            }
          }
        }
      }

      &.no-not-voted-log {
        .modal-content {

          .modal-body {
            > div {
              grid-column: 1 / 3;
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .card {
        .card-body {
          grid-template-columns: 1fr !important;

          > div {
            &.result-diagram {
              grid-column: 1 / 2;
            }
          }
        }
      }
    }
  }

  &.no-not-voted-log {
    .row {
      .card {
        .card-body {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

// Main styles
@import './app.scss';

// Hyper Icons
@import './icons.scss';